'use strict';

export default class Form {
	constructor(options){
		this._polyfill();
		Object.assign(this._options = {}, this._default(), options);
	}

	_polyfill() {

		String.prototype.strReverse = function() {
			var newstring = "";
			for (var s = 0; s < this.length; s++) {
				newstring = this.charAt(s) + newstring;
			}
			return newstring;
		};

		//assign
		if (typeof Object.assign !== 'function') {
			Object.assign = function (target, varArgs) { // .length of function is 2
				'use strict';
				if (target === null) { // TypeError if undefined or null
					throw new TypeError('Cannot convert undefined or null to object');
				}

				var to = Object(target);

				for (var index = 1; index < arguments.length; index++) {
					var nextSource = arguments[index];

					if (nextSource !== null) { // Skip over if undefined or null
						for (var nextKey in nextSource) {
							// Avoid bugs when hasOwnProperty is shadowed
							if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
								to[nextKey] = nextSource[nextKey];
							}
						}
					}
				}
				return to;
			};
		}

	}

	_default() {
		return {
			fields: {
				current: {
					empty: {
						message: 'Заполните поле'
					}
				},
				radio: {
					unchecked: {
						message: 'Отметьте один из вариантов'
					}
				},
				email: {
					empty: {
						message: 'Заполните поле'
					},
					value: [
						{
							val: 'test@test.test',
							message: 'Какая, блин, оригинальная почта...'
						}
					],
					pattern: [
						{
							regex: /^[-._a-z0-9а-я]+@(?:[a-z0-9а-я][-a-z0-9а-я]+\.)+[a-zа-я]{2,6}$/i,
							message: 'Проверьте корректность E-mail'
						}
					],
					antiPattern: [
						{
							regex: 'test@test.test',
							message: 'Какая, блин, оригинальная почта...'
						}
					]
				},
				password: {
					empty: {
						message: 'Заполните поле'
					},
					pattern: [
						{
							regex: /[a-z]/i,
							message: 'Используйте строчные символы'
						},
						{
							regex: /[A-Z]/i,
							message: 'Используйте заглавные символы'
						},
						{
							regex: /[0-9]/i,
							message: 'Используйте цифры'
						},
						{
							regex: /\W/i,
							message: 'Используйте спецсимволы'
						}
					],
					antiPattern: [
						{
							regex: /[а-я]/i,
							message: 'Кирилические символы недопустимы в пароле'
						},
						{
							regex: /\s/i,
							message: 'Нельзя использовать пробел'
						}
					],
					min:{
						length: 6,
						message: 'Длина пароля должна быть не менее 6 символов'
					},
					max: {
						length: 999,
						message: 'Длина пароля не должна превышать 999 символов'
					},
				},
				number: {
					empty: {
						message: 'Заполните поле'
					},
					pattern: [
						{
							regex: /^\d+$/i,
							message: 'Поле должно содержать только числовые символы'
						}
					],
				},
				checkbox: {
					unchecked: {
						message: 'Надо тут чекнуть'
					}
				}
			}
		}
	}

	validate(input, type, callback) {
		if ( !type || type === 'true' || type === true ) {
			if ( input.type === 'radio' || input.type === 'checkbox' || input.type === 'email' || input.type === 'password' ){
				type = input.type;
			} else {
				type = 'current';
			}
		}

		let err = [], val, rules = this._options.fields[type];

		val = input.value.trim();

		if ( !!rules ){
			for (let rule in rules) {
				if (rules.hasOwnProperty(rule)) {
					switch (rule){
						case 'empty':
							if ( val === '' ) err.push(rules[rule].message);
							break;
						case 'pattern':
							if ( rules[rule].length ) rules[rule].map(item=>{
								if ( item.regex && item.regex instanceof RegExp && !item.regex.test(val) ) err.push(item.message);
							});
							break;
						case 'antiPattern':
							if ( rules[rule].length ) rules[rule].map(item=>{
								if ( item.regex && item.regex instanceof RegExp && item.regex.test(val) ) {
									err.push(item.message);
								} else if ( item.regex && typeof item.regex === 'string' && val === item.regex ){
									err.push(item.message);
								}
							});
							break;
						case 'min':
							if ( val.length < rules[rule].length ) err.push(rules[rule].message);
							break;
						case 'max':
							if ( val.length > rules[rule].length ) err.push(rules[rule].message);
							break;
						case 'unchecked':
							if ( type === 'radio' ){
								let i = 0, radio = document.querySelectorAll('input[name=' + input.name + ']'), checked = false;

								for (i; i < radio.length; i++ ){
									if ( radio[i].checked ) {
										checked = true;
										break;
									}
								}
								if ( !checked ) err.push(rules[rule].message);

							} else if ( !input.checked ) {
								err.push(rules[rule].message);
							}
							break;
						default:

					}
				}
			}
		}

		if (callback && typeof callback === 'function') callback({errors: err, value: val, type: type});
	}

}